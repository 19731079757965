import * as PropTypes from "prop-types";
import React from "react";

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import {useNavigate} from "react-router-dom";

const classifications = [
    {value: 'estag', label: 'Contrato de estágio'}
]
export default function SelectDocClassification(props) {
    const [classification, setClassification] = React.useState(classifications[0]);
    const navigate = useNavigate()
    const handleChange = (event) => {
        setClassification(event.target.value);
    };
    return <FormControl variant="filled" sx={{m: 0, minWidth: 200}} style={{display: 'flex'}}>
        <InputLabel id="demo-simple-select-standard-label">Classificação</InputLabel>
        <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={classification.value}
            onChange={handleChange}
            label="Classificação"
        >
            <MenuItem onClick={() => navigate('/home/documents/new/classification/')} value="">
                <IconButton style={{color: 'black'}} onClick={() => alert("oi")}>
                    <AddCircleRoundedIcon/>
                </IconButton>
                Adicionar nova classificação
            </MenuItem>
            {classifications.map(classification => (
                <>
                    <MenuItem key={classification.value}
                              value={classification.value}>{classification.label}
                    </MenuItem>
                </>

            ))}
        </Select>
    </FormControl>;
}
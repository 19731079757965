import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import FilterListDocuments from "./../FilterListDocuments";
import {changeLabel, toolbarManager} from "../../store/updateLabel";
import { useSelector, useDispatch } from 'react-redux'

import Chip from '@mui/material/Chip';

const drawerWidth = 240;

function PageTemplate(props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const dispatch = useDispatch()
    const labelSelector = useSelector((state) => state.toolbarManager.value);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const changeLabelToFilter = () => {
        setExpanded(!expanded)
        if (labelSelector.includes('Filtrar documentos'))
            dispatch(changeLabel('Documentos'));
        else
            dispatch(changeLabel('Filtrar documentos'));
    }

    return (
        <>
            <CssBaseline/>
            <AppBar
                position="fixed"
                sx={{
                    width: {sm: `calc(100% - ${drawerWidth}px)`},
                    ml: {sm: `${drawerWidth}px`},
                    boxShadow: '1px solid'
                }}
            >
                <Toolbar
                    style={{backgroundColor: "white"}}
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        style={{color: 'black'}}
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {sm: 'none'}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Accordion onChange={() => changeLabelToFilter()}
                        style={{boxShadow: 'none', border: 'none', width: '100%',
                            justifyContent: 'space-between'}}
                    >
                        <AccordionSummary expandIcon={<SearchIcon/>}>
                            <Typography variant="h6" noWrap component="div">
                                <span>{labelSelector}</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FilterListDocuments/>
                        </AccordionDetails>
                    </Accordion>
                </Toolbar>
            </AppBar>
        </>
    );
}

PageTemplate.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default PageTemplate;

import React from "react";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import DriveFileMoveRoundedIcon from '@mui/icons-material/DriveFileMoveRounded';
import CreateNewFolderRoundedIcon from '@mui/icons-material/CreateNewFolderRounded';
import AutoDeleteRoundedIcon from '@mui/icons-material/AutoDeleteRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

function FilterListDocuments() {
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [filters, setFilters] = React.useState([]);
    const names = [
        'Estágio',
        'Altas',
        'Relatórios',
    ];

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function showOptions() {
        setOpen(!open)
    }

    return (
        <section>
            <ButtonGroup ref={anchorRef}><IconButton onClick={showOptions}><FilterAltRoundedIcon
                style={{color: "black"}}></FilterAltRoundedIcon></IconButton></ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                    padding: 0.5
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >

                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {names.map((name) => {
                                        function selectFilter(name: string) {
                                            const indexOf = filters.indexOf(name);
                                            if (indexOf === -1) {
                                                setFilters(filters.concat(name));
                                            }
                                            else {
                                                const updatedFilters = filters.filter(filter => filter && filter !== name);
                                                setFilters(updatedFilters)
                                            }
                                        }

                                        return <MenuItem
                                            key={name}
                                            value={name}
                                            onClick={() => selectFilter(name)}
                                        >
                                            <Checkbox
                                                edge="start"
                                                checked={filters.indexOf(name) !== -1}
                                                tabIndex={-1}
                                            /> {name}
                                        </MenuItem>
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </section>)
}

export default function ActionsDocButtons(props) {
    return (<section style={{textAlign: "left"}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{marginLeft: 10, display: 'flex', gap: 25}}>
                <Checkbox/>
                <Button><AutoDeleteRoundedIcon style={{color: "black"}}></AutoDeleteRoundedIcon></Button>
                <Button><DriveFileMoveRoundedIcon style={{color: "black"}}></DriveFileMoveRoundedIcon></Button>
                <Button><CreateNewFolderRoundedIcon style={{color: "black"}}></CreateNewFolderRoundedIcon></Button>
            </div>
            <FilterListDocuments/>
        </div>
    </section>);
}

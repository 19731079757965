import React from "react";
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PdfViewer from "../../../components/PdfViewer";
import TextField from '@mui/material/TextField';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

const collapseStyle = {
    boxShadow: '1px 1px 5px #8DA9C4',
    bgColor: 'background.paper',
    padding: '10px 25px',
    margin: 5,
}


const docCardStyle = {
    boxShadow: '1px 1px 5px #8DA9C4',
    bgColor: 'background.paper',
    padding: 25,
};

export default function SingleDocument(props: { id: any }) {
    const id = props.id
    return (
        <section>
            <Box style={docCardStyle}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: 'flex', flex: '1'}}>
                        <h2>1. Teste primeiro documento</h2>
                    </div>
                    <Divider/>
                    <ButtonGroup style={{display: 'flex', gap: 20}}>
                        <Button
                            variant="outlined"
                            size="small"
                            style={{borderRadius:50, color: "black"}}
                        >
                            Compartilhar
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            style={{borderRadius:50, color: "black"}}
                        >
                            Editar
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            style={{borderRadius:50, color: "black"}}
                        >
                            Excluir
                        </Button>
                        <Button
                            style={{borderRadius:50, color: "black"}}
                            onClick={() => window.open("/assets/example.pdf")}
                            edge="end" aria-label="comments">Visualizar documento
                            <OpenInNewRoundedIcon />
                        </Button>
                    </ButtonGroup>
                    <div>
                        <h1>Criado por: João Paulo Mendonça</h1>
                        <p>Data criação: 13/11/2022 - 18:00:00</p>
                        <p>Última alteração: 13/11/2022 - 18:53:09</p></div>
                    <Divider/>
                </div>
                <div style={{padding: '10px 0px'}}>
                    <SimpleAccordion/>
                </div>
            </Box>
        </section>
    );
}

const indexes = [{label: 'Matrícula', value: '707449'}, {label: 'Nome', value: ''}]

function SimpleAccordion() {
    return (
        <div style={{ boxShadow: '1px 1px 5px #8DA9C4'}}>
            {/*<Accordion>*/}
            {/*    <AccordionSummary*/}
            {/*        expandIcon={<ExpandMoreIcon/>}*/}
            {/*        aria-controls="panel1a-content"*/}
            {/*        id="panel1a-header"*/}
            {/*    >*/}
            {/*        <Typography>Visualizar documento</Typography>*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails>*/}
            {/*        <PdfViewer/>*/}
            {/*    </AccordionDetails>*/}
            {/*</Accordion>*/}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Configurar índices</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                        {indexes.map((index, position) => {
                            const displayItemLabel = position + 1 + '. ' + index.label;
                            return (
                                <ListItem key={index.label} style={{display: 'flex'}}>
                                    <ListItemText primary={displayItemLabel}/>
                                    <TextField value={index.value} variant="filled"/>
                                </ListItem>
                            );
                        })}
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>Configurar permissões</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion disabled>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography>Configurar signatários</Typography>
                </AccordionSummary>
            </Accordion>
        </div>
    )
        ;
}
import {Outlet} from "react-router-dom";
import PageTemplate from "../PageTemplate";
import React from "react";
import Box from '@mui/material/Box';
import Sidebar from "../Sidebar";

const Home = () => {
    return (
        <section>
            <Box sx={{display: 'flex'}}>
                <Sidebar></Sidebar>
                <Outlet/>
            </Box>
        </section>
    )
};

export default Home;
import {Outlet, useParams} from 'react-router-dom'
import React from "react";
import PageTemplate from "../../components/PageTemplate";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import SingleDocument from "./singleDocument";
import {drawerWidth} from "../../components/Sidebar/options";


function SingleDocumentPage(props) {
    const {id} = useParams()

    return (
        <section style={{marginLeft: drawerWidth / 2, padding: 25}}>
            <Toolbar/>
            <Box>
                <PageTemplate/>
                <SingleDocument id={id}/>
                <Outlet/>
            </Box>
        </section>
    );
}

export default SingleDocumentPage;
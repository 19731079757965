import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import SettingsPage from "./pages/SettingsPage";
import MyDocumentsPage from "./pages/MyDocumentsPage";
import UploadFolder from "./components/UploadFolder";
import UploadTemplate from "./components/UploadTemplate";
import NewDocumentPage from "./pages/UploadDocumentPage";
import SingleDocumentPage from "./pages/SingleDocumentPage";
import NewClassificationPage from "./pages/NewClassificationPage";
import ClassificationsPage from "./pages/ClassificationsPage";


function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path='home' element={<Home/>}>
                    <Route path="document/:id" element={<SingleDocumentPage/>} />
                    <Route path="classifications/" element={<ClassificationsPage/>}>

                    </Route>
                    <Route path="documents/" element={<MyDocumentsPage/>}>
                        <Route path="new">
                            <Route path="document" element={<NewDocumentPage/>}/>
                            <Route path="classification" element={<NewClassificationPage/>}/>
                            <Route path="folder" element={<UploadFolder/>}/>
                            <Route path="template" element={<UploadTemplate/>}/>
                        </Route>
                    </Route>
                    <Route path="settings" element={<SettingsPage/>}/>
                    <Route path="trash" element={<SettingsPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
);
}

export default App;

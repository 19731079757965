import React, {useMemo, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Dropzone from 'react-dropzone'
import TextField from '@mui/material/TextField';

import {useDropzone} from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux'
import { closeModal as closeUploderModal}  from '../../store/uploadModal.js'
import SelectDocClassification from "../../components/SelectDocClassification";

const doc = {name: 'teste', token: 'adf2dff-3fas2d-5af34'}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const thumbsContainer = {
    display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 16, placeContent: 'center'
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex', minWidth: 0, overflow: 'hidden'
};

const img = {
    display: 'block', width: 'auto', height: '100%'
};

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: 'rgb(189, 189, 189)',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    minWidth: 400
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export default function NewDocumentPage(props) {
    const uploaderModal = useSelector((state) => state.uploadModalManager.value)
    const dispatch = useDispatch()
    const [files, setFiles] = useState([]);
    const navigate = new useNavigate()

    const {
        isFocused, isDragAccept, isDragReject
    } = useDropzone({accept: {'image/*': []}});

    const style = useMemo(() => ({
        ...baseStyle, ...(isFocused ? focusedStyle : {}), ...(isDragAccept ? acceptStyle : {}), ...(isDragReject ? rejectStyle : {})
    }), [isFocused, isDragAccept, isDragReject]);

    const createDocument = () => {
        dispatch(() => closeCreationOptions())
        navigate(`/home/document/undefined/`)
    };

    function getDrop() {
        return acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    }


    const thumbs = files.map(file => (<div style={thumb} key={file.name}>
        <div style={thumbInner}>
            <img
                alt={"Preview"}
                src={file.preview}
                style={img}
                onLoad={() => {
                    URL.revokeObjectURL(file.preview)
                }}
            />
        </div>
    </div>));

    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    function getDropZone() {
        return files.length === 0 && <Dropzone onDrop={getDrop()}>
            {({getRootProps, getInputProps}) => (<section>
                <div {...getRootProps({style})}>
                    <input {...getInputProps()} />
                    <p style={{color: 'rgb(148, 163, 184)'}}>Selecione o documento</p>
                </div>
            </section>)}
        </Dropzone>;
    }
    function closeCreationOptions() {
        navigate('/home/documents')
        // dispatch(closeUploderModal());
    }

    const classifications = [
        {label: "Matrícula"},
        {label: "Nome do aluno"},
        {label: "CPF"},
        {label: "Data nascimento"},
        {label: "Nome da mãe"},
        {label: "Nome do pai"},
    ];
    return (<div>
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            // onClose={createDocument}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Novo documento</DialogTitle>
            <DialogContent style={{minWidth: 450}}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 30, margin: '10px 0px 30px 0px'}}>
                    <TextField id="standard-basic" label="Nome do documento" required variant="filled"/>
                    <SelectDocClassification/>
                    {/*{classifications.map((classification, index) => <TextField label={classification.label} variant="filled"/>)}*/}
                </div>
                {getDropZone()}
                <aside style={thumbsContainer}>
                    {thumbs}
                </aside>

            </DialogContent>
            <Divider variant="middle" />
            <DialogActions style={{justifyContent: 'space-between'}}>
                <Button style={{color: 'grey'}} onClick={closeCreationOptions}>Descartar</Button>
                <Button onClick={createDocument}>Criar</Button>
            </DialogActions>
        </Dialog>
    </div>);
}
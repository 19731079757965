import { createSlice } from '@reduxjs/toolkit'

export const uploadModalManager = createSlice({
    name: 'uploadModalManager',
    initialState: {
        value: false,
    },
    reducers: {
        openModal: (state) => {
            state.value = true
        },
        closeModal: (state) => {
            state.value = false
        },
    },
})
// Action creators are generated for each case reducer function
export const { openModal, closeModal } = uploadModalManager.actions

// export const { openModal, closeModal } = uploadModalManager.reducers
import ArticleIcon from '@mui/icons-material/Article';
import DeleteRoundedIcon from '@mui/icons-material/Delete';
import ClassIcon from '@mui/icons-material/Class';
import SettingsIcon from '@mui/icons-material/Settings';

export const drawerWidth = 240;
export const sidebarPrimaryOptions = [
    {label: 'Documentos', icon: <ArticleIcon/>, to: '/home/documents/'},
    {label: 'Classificações', icon: <ClassIcon/>, to: '/home/classifications/'}
];
export const sidebarSecondaryOptions = [
    {label: 'Configurações', icon: <SettingsIcon/>, to: '/home/settings/'},
    {label: 'Lixeira', icon: <DeleteRoundedIcon/>, to: '/home/trash/'}
];
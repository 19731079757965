import { useDispatch } from 'react-redux'
import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {useNavigate} from "react-router-dom";
import {openModal} from "../../store/uploadModal";

const options = [
    {label: 'Novo documento', path: '/home/documents/new/document/'},
    {label: 'Novo modelo', path: '/home/documents/new/model/'},
    {label: 'Nova pasta', path: '/home/documents/new/folder/'}
];

const NewUploadButton = () => {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch()
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const navigate = new useNavigate()

    const openUploadModal = (event, path) => {
        dispatch(openModal())
        navigate(path);
        setSelectedIndex(path);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup variant="text" ref={anchorRef} aria-label="split button">
                <Button
                    variant="contained"
                    size="large"
                    style={{borderRadius:50, backgroundColor: 'white'}}
                    onClick={handleToggle}
                >
                    <span><CloudUploadIcon style={{verticalAlign: 'bottom'}}/> NOVO</span>
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                    padding: 0.5
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option.label}
                                            disabled={index === 2}
                                            selected={index === selectedIndex}
                                            onClick={(event) => openUploadModal(event, option.path)}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};

export default NewUploadButton;
import React from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import {useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux'
import {changeLabel} from "../../store/updateLabel";


export function SingleListDocument(props) {
    const doc = props.doc;
    const [checked, setChecked] = React.useState([0]);
    const navigate = useNavigate();

    const selectDoc = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const labelId = `checkbox-list-label-${doc.id}`;
    const dispatch = useDispatch()
    const openDoc = (doc) => () => {
        dispatch(changeLabel('#' + doc.id +' - ' + doc.name))
        navigate(`/home/document/${doc.token}/`)
    }

    return (
        <>
            <ListItem secondaryAction={<IconButton edge="end" aria-label="comments"><MoreHorizIcon/></IconButton>}>
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={checked.indexOf(doc) !== -1}
                        tabIndex={-1}
                        onChange={selectDoc(doc)}
                        inputProps={{'aria-labelledby': labelId}}
                    />
                </ListItemIcon>
                <ListItemButton onClick={openDoc(doc)}>
                    <ListItemText id={labelId} primary={`#${doc.id} - ${doc.name}`}/>
                </ListItemButton>
            </ListItem>
        </>
    )
}

export default SingleListDocument;

import {drawerWidth} from "../../components/Sidebar/options";
import PageTemplate from "../../components/PageTemplate";
import SingleDocument from "../SingleDocumentPage/singleDocument";
import {Outlet} from "react-router-dom";
import React from "react";
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

const SettingsPage = () => {
    return (
        <section style={{marginLeft: drawerWidth / 2, width: '100%'}}>
            <Toolbar/>
            <Box style={{width: '100%'}}>
                <PageTemplate/>
                <Outlet/>
            </Box>
        </section>
    )
};

export default SettingsPage;
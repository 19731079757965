import {drawerWidth} from "../../components/Sidebar/options";
import PageTemplate from "../../components/PageTemplate";
import {Outlet} from "react-router-dom";
import Box from '@mui/material/Box';
import ClassificationsList from "../../components/ClassificationsList";
import Toolbar from '@mui/material/Toolbar';
import React from "react";

export default function ClassificationsPage(props) {
    return (
        <section style={{marginLeft: drawerWidth / 2, padding: 25, width: '100%'}}>
            <Toolbar/>
            <Box>
                <PageTemplate/>
                <ClassificationsList/>
                <Outlet/>
            </Box>
        </section>
    )
}
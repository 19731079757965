import React, {useState} from 'react';
import {Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.workerSrc =
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const pdfViewerStyle = {
    page: {width: 500}
}

export default function PdfViewer(props) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    function changePage(page: number){
        setPageNumber(page);
    }

    return (
        <section id="pdfViewer" >
            <div style={{display: 'flex', alignItems: 'baseline'}}>
                Page {pageNumber} of {numPages} <Pagination page={pageNumber} onChange={(event, page) => changePage(page)} count={numPages? numPages: 0} showFirstButton showLastButton />
            </div>
            <Divider/>
            <Document file={"/assets/example.pdf"} onLoadSuccess={onDocumentLoadSuccess}>
                {/* TODO: Dynamic width*/}
                <Page annotationLayer={false} width={1000} pageNumber={pageNumber}/>
            </Document>
        </section>
    );
}
import React from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import NewUploadButton from "../NewUploadButton";
import {Link, useNavigate} from "react-router-dom";
import {drawerWidth, sidebarPrimaryOptions, sidebarSecondaryOptions} from "./options";
import {changeLabel} from "../../store/updateLabel";
import { useDispatch } from 'react-redux'
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';


function Sidebar(props) {
    const {window} = props;
    const container = window !== undefined ? () => window().document.body : undefined;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate()

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const dispatch = useDispatch()
    function changeToolbarLabel(label) {
        dispatch(changeLabel(label))
    }

    const drawer = (<div>
        <Toolbar style={{placeContent: 'center'}}>
            <NewUploadButton/>
        </Toolbar>
        <Divider/>
        <div style={{margin: 10, textAlign: 'center'}} >
            <Chip onClick={() => navigate('/home/settings/my-account/')} avatar={<Avatar>M</Avatar>} label="teste.teste@harvard.com"/>
        </div>
        <Divider/>
        <List>
            {sidebarPrimaryOptions.map((object, index) => (
                <Link style={{textDecoration: 'none', color: 'black'}} key={object.label} onClick={() => changeToolbarLabel(object.label)} to={object.to}>
                    <ListItem key={object.label} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {object.icon}
                            </ListItemIcon>
                            <ListItemText primary={object.label}/>
                        </ListItemButton>
                    </ListItem>
                </Link>))}
        </List>
        <Divider/>
        <List>
            {sidebarSecondaryOptions.map((object, index) => (
                <Link style={{textDecoration: 'none', color: 'black'}} key={object.label} onClick={() => changeToolbarLabel(object.label)} to={object.to}>
                    <ListItem key={object.label} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {object.icon}
                            </ListItemIcon>
                            <ListItemText primary={object.label}/>
                        </ListItemButton>
                    </ListItem>
                </Link>))}
        </List>
        <Divider/>
    </div>);

    return (<Box
        component="nav"
        sx={{width: drawerWidth / 2, flexShrink: {sm: 0}}}
        aria-label="mailbox folders"
    >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: {xs: 'block', sm: 'none'}, '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
            }}
        >
            {drawer}
        </Drawer>
        <Drawer
            variant="permanent"
            sx={{
                display: {xs: 'none', sm: 'block'}, '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
            }}
            open
        >
            {drawer}
        </Drawer>
    </Box>);
}


export default Sidebar;
import { createSlice } from '@reduxjs/toolkit'

export const toolbarManager = createSlice({
    name: 'toolbarManager',
    initialState: {
        value: 'Documentos',
    },
    reducers: {
        changeLabel: (state, {type, payload}) => {
            console.log(payload)
            state.value = payload
        }
    },
})
// Action creators are generated for each case reducer function
export const { changeLabel } = toolbarManager.actions

// export const { openModal, closeModal } = uploadModalManager.reducers
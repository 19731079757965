import React from "react";
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {FixedSizeList} from 'react-window';
import SingleListDocument from "../MyDocument";
import {drawerWidth} from "../Sidebar/options";
import ActionsDocButtons from "../ActionsListDocuments";

const documents = [{'id': 1, 'name': 'teste primeiro documento'}, {
    'id': 2,
    'name': 'teste primeiro documento'
}, {'id': 3, 'name': 'teste primeiro documento'}, {'id': 4, 'name': 'teste primeiro documento'},]

const docCardStyle = {
    boxShadow: '1px 1px 5px #8DA9C4',
    margin: '20px 5px',
    width: '99%',
    bgColor: 'background.paper',
};

function MyDocumentList() {

    function renderRow({ index, style }) {
        return (
            <List style={docCardStyle} key={documents[index].id} component="div" disablePadding>
                <SingleListDocument doc={documents[index]}/>
            </List>
        );
    }

    return (
        <Box sx={{padding: '20px 30px', bgColor: 'background.paper'}}>
            < Toolbar/>
            <ActionsDocButtons/>
            <FixedSizeList
                height={1000}
                width={1000}
                itemCount={documents.length}
                itemSize={1}
                // onItemsRendered={onItemsRendered}
                // ref={ref}
            >

                {renderRow}
            </FixedSizeList>
        </Box>);
}

export default MyDocumentList;


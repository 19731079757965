import PageTemplate from "../../components/PageTemplate";
import {Outlet} from "react-router-dom";
import React from "react";
import Box from '@mui/material/Box';
import MyDocumentList from "../../components/MyDocumentList";
import {drawerWidth} from "../../components/Sidebar/options";

const MyDocumentsPage = () => {
    return (
        <section style={{marginLeft: drawerWidth / 2}}>
            <Box sx={{display: 'flex'}}>
                <PageTemplate/>
                <MyDocumentList/>
                <Outlet/>
            </Box>
        </section>
    );
};

export default MyDocumentsPage;
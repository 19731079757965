import {cardStyle} from "../../styles";
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

const bull = (
    <Box
        component="span"
        sx={{display: 'inline-block', mx: '2px', transform: 'scale(0.8)'}}
    >
        •
    </Box>
);
const classifications = [
    {name: "Contrato de estágio", index_count: 5, docs_created: 300, created_at: '15/11/2022'},
    {name: "Contrato de professor", index_count: 15, docs_created: 253, created_at: '15/11/2022'},
    {name: "Financeiro", index_count: 20, docs_created: 312, created_at: '15/11/2022'},
    {name: "Encomendas", index_count: 40, docs_created: 393, created_at: '15/11/2022'},
    {name: "Contrato de estágio", index_count: 58, docs_created: 280, created_at: '15/11/2022'},
    {name: "Contrato de estágio", index_count: 53, docs_created: 615, created_at: '15/11/2022'},
]
export default function ClassificationsList(props) {
    return (
        <section style={cardStyle}>
            <h2>Minhas classificações</h2>

            <List style={{display: 'flex', flexDirection: 'row', gap: 25, flexWrap: 'wrap'}}>
                {classifications.map((classification) => (
                    <Card style={cardStyle}>
                        <ListItemButton style={{display: 'flex', flexDirection: 'column', wordBreak: 'none', width: 250}}>
                            <CardContent>
                                <Typography sx={{fontSize: 21}} variant="h4" component="span">
                                    {classification.name}
                                </Typography>
                                <Typography sx={{mb: 1.5}} color="text.secondary">
                                    Criado em: {classification.created_at}
                                </Typography>
                                <Typography variant="body2">
                                    {bull}{classification.index_count} índices
                                    <br/>
                                    {bull}{classification.docs_created} documentos criados
                                </Typography>
                            </CardContent>
                            {/*<CardActions>*/}
                            {/*    <Button size="small">Visualizar</Button>*/}
                            {/*</CardActions>*/}
                        </ListItemButton>
                    </Card>
                ))}
            < /List>
        </section>
    )
}